/* .cnt {
    color: red;
    width: 100vw;
}



@keyframes view-full {
    100% {
        width: 100%;
        transform: translateY(70%);
    }
} */

.hero-img {
    /* animation: view-full linear forwards;
    animation-timeline: view(); */
    z-index: 10 !important;
}