@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
}

section {
    scroll-snap-align: start;
}

body {
    font-family: "Montserrat", sans-serif;
    overflow-x: hidden;
    background-color: #090312;
}

.font-kanit {
    font-family: "Kanit", sans-serif;
}

.text-stroke-3 {
    text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}

.gradient {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.33),
        rgba(255, 255, 255, 0.19),
        rgba(255, 255, 255, 0.08)
    );
}
.text-9-3 {
    font-size: clamp(0.1875rem, 0.0213rem + 0.6186vw, 0.5625rem);
}

.text-6-12 {
    font-size: clamp(0.375rem, 0.2088rem + 0.6186vw, 0.75rem);
}

.text-16-24 {
    font-size: clamp(1rem, 0.7784rem + 0.8247vw, 1.5rem);
}

.text-12-16 {
    font-size: clamp(0.75rem, 0.6392rem + 0.4124vw, 1rem);
}

.text-10-16 {
    font-size: clamp(0.625rem, 0.4588rem + 0.6186vw, 1rem);
}

.text-11-23 {
    font-size: clamp(0.6875rem, 0.355rem + 1.2371vw, 1.4375rem);}

.text-12-20 {
    font-size: clamp(0.75rem, 0.5284rem + 0.8247vw, 1.25rem);
}
.text-12-24 {
    font-size: clamp(0.75rem, 0.4175rem + 1.2371vw, 1.5rem);
}

.text-16-32 {
    font-size: clamp(1rem, 0.5567rem + 1.6495vw, 2rem);
}

.text-28-56 {
    font-size: clamp(1.75rem, 0.9742rem + 2.8866vw, 3.5rem);
}

.text-20-40 {
    font-size: clamp(1.25rem, 0.6959rem + 2.0619vw, 2.5rem);
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
